
<template>
  <div>
    <router-link to="/client">客户画像</router-link>
    <br />
    <br />
    <router-link to="/follow">跟进</router-link>
    <br />
    <br />
    <router-link to="/business">转为订单</router-link>
    <br />
    <br />
    <router-link to="/studentDetails">学生详情</router-link>
    <br />
    <br />
    <router-link to="/studentDetails">编辑客户</router-link>
    <br />
    <br />
    <router-link to="/fastReply">快捷回复</router-link>
    <br />
    <br />
    <router-link to="/contentEngine">内容引擎</router-link>
    <br />
    <br />
    <router-link to="/toPay">支付订单</router-link>
    <br />
    <br />
    <router-link to="/test">test</router-link>
    <br />
    <br />
  </div>
</template>
<script>
import { useRouter } from "vue-router";

export default {
  setup() {
    const router = useRouter();
    const handleClick = () => {
      router.push({
        path: "/client",
      });
    };
    return {
      handleClick,
    };
    // const count = ref(12);
    // const object = reactive({ foo: "bar" });
    // return () =>
    //   h("span", { style: { color: "red" } }, [count.value, object.foo]);
  },
};
</script>